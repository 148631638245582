import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbar,
  getGridNumericOperators,
  getGridStringOperators,
} from "@mui/x-data-grid";
import Tippy from "@tippyjs/react";
import columns from "./TableColmuns";
import instance from "../../redux/actions/AxiosInstance/authenticated";
import { convertMongooseDateTimeToDDMMYYYYTime } from "../../components/utils";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

const options = {
  day: "numeric",
  month: "long",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};

const Table = ({
  data,
  loading,
  fetchData,
  totalRows,
  task_id,
  task_name,
  created_at,
  listInfo,
  showModal,
  setRenameModal,
  handleRename,
  renameModal,
  list_id,
  viewAll,
  user,
}) => {
  const [paginationModal, setPaginationModal] = useState(
    viewAll
      ? {
          page: 0,
          pageSize: 50,
        }
      : {
          page: 0,
          pageSize: 10,
        }
  );
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState(null);
  const [downloadingResults, setDownloadingResults] = useState(false);

  const [visibility, setVisibility] = useState({});
  const navigate = useNavigate();

  let preparedCoumns = [...columns];

  if (user && !user.isAdmin) {
    // remove is_waterfall and is_mobile_waterfall columns
    preparedCoumns = preparedCoumns.filter((column) => {
      return (
        column.field !== "is_waterfall" &&
        column.field !== "is_mobile_waterfall"
      );
    });
  }

  async function downloadResults() {
    try {
      setDownloadingResults(true);
      const response = await instance.get("/datalake/download", {
        params: {
          sortModel: sortModel,
          filterModel: filterModel,
          paginationModal: paginationModal,
          visibility: visibility,
          list_id: list_id,
        },
      });

      const formattedDateTime = convertMongooseDateTimeToDDMMYYYYTime(
        listInfo.updatedAt
      );

      let filename = `result-${listInfo.name}.csv`;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", filename);
      link.setAttribute("hidden", true);
      document.body.appendChild(link);
      link.click();
      setDownloadingResults(false);
      // console.log(response);
    } catch (e) {
      console.log(e);
      setDownloadingResults(false);
    }
  }

  useEffect(() => {
    var tableParent = document.getElementById("dl-table-parent");
    if (tableParent) {
      var table = document.getElementById("datalakeTable");

      // console.log(tableParent.offsetWidth);
      //set the maxWith of the table to the width of the parent
      table.style.maxWidth = tableParent.offsetWidth - 50 + "px";
    }
  }, []);

  useEffect(() => {
    if (filterModel != null) {
      let timeout = setTimeout(() => {
        fetchData(paginationModal, sortModel, filterModel);
      }, 2000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [filterModel]);

  useEffect(() => {
    // console.log("fetching data", paginationModal, sortModel);
    fetchData(paginationModal, sortModel, filterModel);
  }, [paginationModal, sortModel]);

  let topBar = (
    <>
      <div className="w-full">
        {listInfo ? (
          <>
            {renameModal.status ? (
              <div className="rounded-lg mt-2.5 w-full ">
                <input
                  className="border-[2px] border-slate-200 w-full rounded-lg px-2.5 shadow-sm outline-none capitalize  font-semibold font-Inter text-[#101828] text-xl"
                  type="text"
                  name="enrichment Name"
                  id="enrichment Name"
                  value={renameModal.name}
                  onChange={(e) => {
                    setRenameModal({
                      ...renameModal,
                      name: e.target.value,
                    });
                  }}
                  placeholder="Provide Lead Name"
                  onBlur={() => {
                    handleRename();
                  }}
                />
              </div>
            ) : (
              <div className="flex items-center justify-start">
                <BsArrowLeft
                  onClick={() => {
                    navigate("lead-hub");
                  }}
                  className="h-5 w-5 my-auto mr-1 cursor-pointer"
                />
                <p className="text-lg text-secondary font-semibold text-Inter capitalize">
                  {listInfo?.name}
                </p>
                {!renameModal.status && (
                  <svg
                    version="1.0"
                    onClick={() => {
                      setRenameModal({
                        name: listInfo?.name,
                        status: !renameModal.status,
                      });
                    }}
                    className="cursor-pointer ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18px"
                    height="30px"
                    viewBox="0 0 512.000000 512.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                      fill="#000000"
                      stroke="none"
                    >
                      <path
                        d="M3720 4629 c-145 -22 -268 -78 -393 -179 -40 -32 -637 -624 -1329
-1316 l-1256 -1259 -15 -70 c-210 -975 -249 -1167 -243 -1200 9 -46 57 -101
103 -116 19 -6 54 -9 77 -6 30 4 983 207 1186 252 27 7 2526 2496 2609 2600
279 349 224 860 -121 1131 -169 132 -408 195 -618 163z m223 -324 c243 -51
410 -294 369 -537 -23 -139 -59 -193 -275 -413 l-192 -196 -340 341 -340 340
201 200 c174 174 209 204 266 229 110 50 197 60 311 36z m-688 -1736 c-198
-198 -628 -625 -955 -949 l-596 -588 -423 -91 c-233 -50 -425 -90 -427 -88 -1
1 38 194 88 427 l90 425 951 953 952 952 340 -340 340 -340 -360 -361z"
                      />
                    </g>
                  </svg>
                )}
              </div>
            )}

            <p className="text-sm text-secondarySupport text-Inter">
              Last Updated,{" "}
              {new Date(listInfo?.updatedAt).toLocaleDateString(
                "en-US",
                options
              )}
            </p>
          </>
        ) : (
          <div class="flex animate-pulse space-x-4 w-full">
            <div class="flex-1 space-y-2 py-1">
              <div class="h-4 w-2/12 rounded bg-slate-400"></div>
              <div class="h-4 w-3/12 rounded bg-slate-400"></div>
            </div>
          </div>
        )}
      </div>
      <div className="flex item-center gap-x-3 h-fit justify-end w-full">
        {/* <Tippy content="Coming Soon" arrow={true}> */}
        <button
          onClick={() => {
            downloadResults();
          }}
          disabled={downloadingResults}
          className="px-6 mt-2 py-1.5 text-sm text-Inter text-gray-500  font-[500] rounded-lg border-2 border-gray-500"
        >
          Export all
        </button>
        {/* </Tippy> */}
        <button
          onClick={(e) => {
            showModal();
          }}
          className="px-6 mt-2 py-1.5 text-sm text-Inter bg-primary text-white font-[500] rounded-lg border-[1px] border-primary"
        >
          Import CSV
        </button>
      </div>
    </>
  );

  if (viewAll) {
    topBar = (
      <div className="flex item-center justify-between">
        <div className="w-full">
          <p className="text-lg text-secondary font-semibold text-Inter capitalize">
            Records
          </p>
          <p className="text-sm text-secondarySupport text-Inter">
            View all data lake records
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex item-center justify-between">{topBar}</div>
      <div id="datalakeTable" style={{ height: 560 }} className=" mt-5">
        <DataGrid
          sx={{
            overflowX: "scroll",
            border: 0,
            "& .css-17jjc08-MuiDataGrid-footerContainer": {
              borderTop: 0,
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "500",
              fontSize: "13px",
              lineHeight: "18px",
              fontFamily: "Inter",
            },
            "& .MuiSvgIcon-root": {
              fontSize: "1.15rem",
            },
            "& .MuiDataGrid-iconSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-cell": {
              whiteSpace: "pre-wrap !important",
            },
            "& .MuiDataGrid-columnHeader:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-selectedRowCount": {
              opacity: 0,
            },
          }}
          sortingMode="server"
          rows={loading ? [] : data}
          columns={preparedCoumns}
          getRowId={(row) => row._id}
          filterMode="server"
          onFilterModelChange={(model) => {
            setFilterModel(model);
          }}
          onSortModelChange={(model) => {
            // console.log(model);
            setSortModel(model);
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
          onPaginationModelChange={(model) => {
            setPaginationModal(model);
          }}
          paginationModel={paginationModal}
          rowCount={totalRows}
          loading={loading}
          slots={{
            toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
            },
          }}
          columnVisibilityModel={visibility}
          onColumnVisibilityModelChange={(model) => {
            setVisibility(model);
          }}
        ></DataGrid>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.global.user,
});

export default connect(mapStateToProps)(Table);
