import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import instance from "../../redux/actions/AxiosInstance/unAuthenticated";

// get query params
import { useLocation } from "react-router-dom";

function ResetPassword() {
  const [password, setPassword] = useState("");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const [loading, setLoading] = useState(false);

  const resetPasswordHandler = async (e) => {
    if (!password || !token) {
      toast.error("Please provide password and token");
      return;
    }
    try {
      setLoading(true);

      const response = await instance.post(
        "/user/reset-password",
        {
          password: password,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;

      console.log(response, "response in reset pass");

      setLoading(false);

      toast.success("Password reset successfully");

      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);

      navigate("/enrichment");
    } catch (e) {
      setLoading(false);
      toast.error("Something went wrong please try again later");
    }
  };

  const navigate = useNavigate();
  return (
    <div className="grid place-content-center items-center h-screen w-full">
      <div className="px-3 w-full">
        <p className="font-Inter text-center text-md font-semibold text-2xl text-[#101828] mt-4">
          Reset Password
        </p>
        <p className="font-Inter text-center text-sm text-[#475467] mt-2">
          Provide new password for your account.
        </p>
        <div className="mt-4">
          <label
            htmlFor="password"
            className="text-[#4E5674] font-[500] font-Inter"
          >
            New Password *
          </label>
          <div className="mt-1 flex items-center rounded-lg border-2 py-2 px-3">
            <input
              className="border-none outline-none w-full font-Inter"
              type="password"
              name="password"
              id="password"
              placeholder="••••••••"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
        <div className="flex justify-center items-center gap-x-4 mt-5">
          {loading ? (
            <button
              disabled
              type="button"
              className="font-base mt-6 mb-2 block w-full rounded-lg bg-primary py-2.5 text-white"
            >
              <svg
                role="status"
                className="inline mr-3 w-4 h-4 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
              Loading...
            </button>
          ) : (
            <button
              onClick={() => {
                resetPasswordHandler();
              }}
              type="text"
              className="text-[12px] text-sm w-full cursor-pointer font-semibold font-Inter px-5 rounded-lg py-2 text-white bg-primary shadow-navButton hover:shadow-navButton border-[1px] "
            >
              Reset Password
            </button>
          )}
        </div>
        <p
          onClick={() => {
            navigate("/signin");
          }}
          className="font-Inter cursor-pointer font-[500] text-sm text-[#475467] mt-5 flex items-center justify-center gap-x-3"
        >
          <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3332 7.00008H1.6665M1.6665 7.00008L7.49984 12.8334M1.6665 7.00008L7.49984 1.16675"
              stroke="#475467"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Back to log in
        </p>
      </div>
    </div>
  );
}

export default ResetPassword;
