import React, { useState } from "react";
import Modal from "../../components/Modal";
import { useNavigate } from "react-router-dom";
import instance from "../../redux/actions/AxiosInstance/unAuthenticated";
import { toast } from "sonner";
function ForgotPassword() {
  const navigate = useNavigate();
  const [sendModalStatus, setSendModalStatus] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const forgotPassHandler = async (e) => {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address");
      return;
    }
    setSendModalStatus(false);

    try {
      setLoading(true);

      const response = await instance.post("/user/forgot", {
        email: email,
      });

      console.log(response, "response in forgot pass");

      setSendModalStatus(true);
      setLoading(false);
    } catch (e) {
      toast.error("Something went wrong please try again later");
      setLoading(false);
    }
  };

  return (
    <>
      <div className="grid place-content-center items-center h-screen">
        <div className="px-3 w-full max-w-md">
          <p className="font-Inter text-center text-md font-semibold text-2xl text-[#101828] mt-4">
            Forgot password?
          </p>
          <p className="font-Inter text-center text-sm text-[#475467] mt-2">
            No worries, we’ll send you reset instructions.
          </p>
          <div className="mt-5">
            <label
              htmlFor="email"
              className="text-[#4E5674] font-[500] text-sm font-Inter"
            >
              Your email
            </label>
            <div className="mb-4 mt-1 flex items-center rounded-lg border-2 py-1.5 px-3">
              <input
                className="border-none outline-none w-full"
                type="text"
                name="email"
                id="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
              />
            </div>
          </div>
          <div className="flex justify-center items-center gap-x-4 mt-5">
            {loading ? (
              <button
                disabled
                type="button"
                className="font-base mt-6 mb-2 block w-full rounded-lg bg-primary py-2.5 text-white"
              >
                <svg
                  role="status"
                  className="inline mr-3 w-4 h-4 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                Loading...
              </button>
            ) : (
              <button
                onClick={() => {
                  // setSendModalStatus(true);
                  forgotPassHandler();
                }}
                type="text"
                className="font-semibold font-Inter text-sm mt-6 mb-2 block w-full rounded-lg transition duration-200 ease-in bg-primary hover:bg-primary py-2.5 text-white"
              >
                Recover password
              </button>
            )}
          </div>
          <p
            className="font-Inter cursor-pointer font-[500] text-sm text-[#475467] mt-5 flex items-center justify-center gap-x-3"
            onClick={() => {
              console.log("clicked");
              navigate("/signin");
            }}
          >
            <svg
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3332 7.00008H1.6665M1.6665 7.00008L7.49984 12.8334M1.6665 7.00008L7.49984 1.16675"
                stroke="#475467"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Back to log in
          </p>
        </div>
      </div>

      {sendModalStatus && (
        <Modal width="w-[400px]">
          <p className="font-Inter text-center text-md font-semibold text-2xl text-[#101828]">
            Check your email
          </p>
          <p className="font-Inter text-center text-sm text-[#475467] mt-2 mx-auto">
            We sent a password reset link to{" "}
            <span className="font-[500]">{}</span>
          </p>

          <button
            onClick={() => {
              // open email in new tab
              window.open("https://mail.google.com", "_blank");
            }}
            className="font-semibold font-Inter text-sm mt-6 mb-2 block w-full rounded-lg transition duration-200 ease-in bg-primary hover:bg-primary py-2.5 text-white m-auto"
          >
            Open Email App
          </button>

          <p className="font-Inter text-center text-sm text-[#475467] mt-4  mx-auto">
            Didn’t receive the email?{" "}
            <span
              className="font-[500] text-[#6941C6]"
              onClick={() => {
                forgotPassHandler();
              }}
            >
              Click to resend
            </span>
          </p>

          <p
            onClick={() => {
              console.log("clicked");
              navigate("/signin");
            }}
            className="font-Inter cursor-pointer font-[500] text-sm text-[#475467] mt-4 flex items-center justify-center gap-x-3"
          >
            <svg
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3332 7.00008H1.6665M1.6665 7.00008L7.49984 12.8334M1.6665 7.00008L7.49984 1.16675"
                stroke="#475467"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Back to log in
          </p>
        </Modal>
      )}
    </>
  );
}

export default ForgotPassword;
