import React, { useState, useEffect, useTransition } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "sonner";
import {
  handleSubscription,
  updateStripe,
  getUserBillingInfo,
  stripeCustomerPortal,
} from "../redux/actions/stripeAction";
import { connect } from "react-redux";
import { failAlert, successAlert } from "../components/sweetAlert";
import { useLocation, useNavigate } from "react-router-dom";
// Get the stripe instance
export const getStripe = () => {
  return loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEYS);
};
function Setting(props) {
  const [loading, setLoading] = useState(null);
  const [currentPlan, setCurrentPlan] = useState({});
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [cancelling, setCancelling] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const stripeSessionId = new URLSearchParams(location.search).get("session");
  const checkoutResult = new URLSearchParams(location.search).get("checkout");

  const {
    handleSubscription,
    billingInfo,
    getUserBillingInfo,
    updateStripe,
    stripeCustomerPortal,
    user,
    customerPortalData,
    totalLeadlists,
    stripe,
  } = props;

  useEffect(() => {
    const handleStripe = async () => {
      if (!stripeSessionId || !checkoutResult) return;

      if (checkoutResult === "fail") {
        failAlert({ title: "Payment failed" });
        return;
      }
      if (!stripeSessionId) return;

      successAlert({ title: "Payment successful" });

      await updateStripe(stripeSessionId);

      navigate("/settings");
    };

    (async () => {
      await handleStripe();
    })();
  }, [stripeSessionId]);

  useEffect(() => {
    (async () => {
      try {
        await getUserBillingInfo();
        setCurrentPlan(billingInfo);
      } catch (err) {
        console.log("Err in fetching billing information");
      }
    })();
  }, []);

  const handleStripe = async () => {
    // console.log(billingInfo, "billingInfo");
    if (
      user.stripeCustomerId === null ||
      user.stripeCustomerId === undefined ||
      billingInfo?.stripeCustomer === null
    ) {
      toast.error("Please add payment method first");
      return;
    }

    toast.promise(
      stripeCustomerPortal().then((data) => {
        if (data.status) {
          // console.log(data); // You might want to log the actual data here, such as `data.portal`
          window.open(data.portal, "_blank", "noopener,noreferrer");
          return "Redirecting to portal..."; // Return success message for the toast
        } else {
          throw new Error("Unable to access customer portal"); // Throw error to be caught for toast error message
        }
      }),
      {
        loading: "Loading...",
        success: (data) => data, // The success property receives the resolved value from the promise
        error: "Something went wrong", // In case of error the promise will be rejected and this message will show
      }
    );
  };

  const plans = [
    {
      name: "Enhanced",
      price: "37.00",
      features: [
        "1000 Credits per month",
        "Instant Email Enrichment",
        "Instant Mobile Enrichment",
      ],
      planId: process.env.REACT_APP_STRIPE_SOLO,
    },
    {
      name: "Agency",
      price: "77.00",
      features: [
        "3500 Credits per month",
        "Instant Email Enrichment",
        "Instant Mobile Enrichment",
      ],
      planId: process.env.REACT_APP_STRIPE_AGENCY,
      popular: true,
    },
    {
      name: "Growth",
      price: "197.00",
      features: [
        "10000 Credits per month",
        "Instant Email Enrichment",
        "Instant Mobile Enrichment",
      ],
      planId: process.env.REACT_APP_STRIPE_GROWTH,
    },
  ];

  const buyPlan = async (planId) => {
    try {
      setLoading(planId);
      const sessionData = await handleSubscription(planId);

      if (sessionData) {
        const stripe = await getStripe();

        stripe.redirectToCheckout({ sessionId: sessionData.sessionId });
      }
    } catch (err) {
      console.log("Something went wrong in buying plan");
      console.log(err);
    } finally {
      setLoading(null);
    }
  };

  // console.log(stripe?.planId, "stripe");

  return (
    <div className="py-4 w-full min-h-screen relative overflow-hidden">
      <div className="flex-1 px-8 flex items-center justify-center sm:items-stretch sm:justify-start">
        <p className="text-2xl font-Inter font-[600] mt-[0.1px] text-[#101828] ease-in duration-300">
          Setting
        </p>
      </div>

      <hr className="bg-gray-200 h-[1.15px] mt-[13px]" />

      <div className="flex items-center justify-between w-full px-8 py-5 pb-0">
        <p className="text-xl  font-Inter font-[600] text-[#101828] ease-in duration-300 fade-in">
          Payment Plans
        </p>

        <div className="flex items-baseline gap-x-3">
          {/* <p className="text-Inter text-gray-600 font-semibold">
            Total Leads : {user ? user.total_leads_used : 0} /{" "}
            {user ? user.current_leads_limit : 0}

          </p> */}
          <button
            onClick={handleStripe}
            type="text"
            className="inline-flex items-center gap-x-2 text-[14px] font-semibold font-Inter px-4 rounded-lg border-[1px] border-[#D0D5DD] py-2 shadow-leadButton hover:shadow-navButton"
          >
            <svg
              width="20"
              height="16"
              viewBox="0 0 20 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.66602 5.33317H18.3327V3.83317C18.3327 2.89975 18.3327 2.43304 18.151 2.07652C17.9912 1.76292 17.7363 1.50795 17.4227 1.34816C17.0661 1.1665 16.5994 1.1665 15.666 1.1665H4.33268C3.39926 1.1665 2.93255 1.1665 2.57603 1.34816C2.26243 1.50795 2.00746 1.76292 1.84767 2.07652C1.66602 2.43304 1.66602 2.89975 1.66602 3.83317V10.1665C1.66602 11.0999 1.66602 11.5666 1.84767 11.9232C2.00746 12.2368 2.26243 12.4917 2.57603 12.6515C2.93255 12.8332 3.39926 12.8332 4.33268 12.8332H9.16602M12.0827 14.4998L13.7702 14.1623C13.9173 14.1329 13.9909 14.1182 14.0595 14.0913C14.1204 14.0674 14.1782 14.0364 14.2319 13.999C14.2923 13.9569 14.3454 13.9038 14.4515 13.7977L17.916 10.3332C18.3763 9.87293 18.3763 9.12674 17.916 8.6665C17.4558 8.20627 16.7096 8.20627 16.2493 8.66651L12.7848 12.131C12.6787 12.2371 12.6257 12.2902 12.5835 12.3506C12.5461 12.4043 12.5151 12.4622 12.4912 12.5231C12.4643 12.5917 12.4496 12.6652 12.4202 12.8124L12.0827 14.4998Z"
                stroke="#475467"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p
              onClick={handleStripe}
              className="text-sm font-semibold text-center text-[#344054]"
            >
              Manage subscription
            </p>
          </button>
        </div>
      </div>
      {/* <div className="px-8 py-1">
        <p className="text-lg text-[#101828] font-[600] mt-4">
          Included with All Plans
        </p>
        <p className="text-gray-600 text-md ml-1 mb-1 mt-2">
          Free use of your own Apollo.io API Key and unlimited email
          verification
        </p>
        <p className="text-gray-600 mb-5 text-md ml-1">
          Need More Apollo Keys? Purchase{" "}
          <a
            className="text-blue-800 underline"
            target="#blank"
            href="https://get.apollo.io/19ko4c5fqgya"
          >
            here
          </a>
        </p>
      </div> */}

      <section class="text-gray-600 body-font overflow-hidden mt-12">
        <div class="container px-8 mx-auto">
          <div class="flex flex-wrap -m-4">
            {plans.map((item, key) => (
              <div key={key} class="p-4 xl:w-1/4 md:w-1/2 w-full">
                <div class="h-full p-6 rounded-lg border-[1px] flex flex-col relative overflow-hidden">
                  {item.popular && (
                    <span class="bg-primary border-primary text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
                      POPULAR
                    </span>
                  )}
                  <h2 class="text-sm tracking-widest title-font mb-1 font-medium text-Inter">
                    {item.name}
                  </h2>
                  <h1 class="text-5xl text-gray-900 leading-none flex items-center text-Inter pb-4 mb-4 border-b border-gray-200">
                    <span>${item.price}</span>
                    <span class="text-lg ml-1 font-normal text-gray-500 text-Inter">
                      /mo
                    </span>
                  </h1>

                  <div className="mt-2.5 space-y-3 mb-4">
                    {item.features.map((feature, key) => (
                      <p class="flex items-center text-gray-600  text-Inter">
                        <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-orange-100 text-white rounded-full flex-shrink-0">
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 20 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18 2L7 13L2 8"
                              stroke="#FF692E"
                              stroke-width="2.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        {feature}
                      </p>
                    ))}
                  </div>

                  {
                    loading === item.planId ? (
                      <button
                        disabled
                        type="button"
                        className="mx-auto mt-4 text-primary text-Inter border-primary border-[1px] py-2 px-4 w-full font-semibold focus:outline-none hover:bg-priborder-primary rounded"
                      >
                        <svg
                          role="status"
                          className="inline mr-3 w-4 h-4 text-primary animate-spin"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#E5E7EB"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentColor"
                          />
                        </svg>
                        Loading...
                      </button>
                    ) : billingInfo?.stripeCustomer?.planId === item.planId ? (
                      <button className="mt-auto font-semibold font-Inter text-sm block w-full rounded-lg transition mx-autoduration-200 ease-in bg-primary hover:bg-primary py-2.5 text-white">
                        Current Plan
                      </button>
                    ) : (
                      <button
                        disabled={loading !== null}
                        onClick={() => buyPlan(item.planId)}
                        class={`mt-auto mx-auto text-primary text-Inter border-primary border-[1px] py-2 px-4 w-full font-semibold focus:outline-none hover:bg-priborder-primary rounded`}
                      >
                        Buy
                      </button>
                    )

                    // (
                    //   <button
                    //     disabled={loading !== null}
                    //     onClick={() => buyPlan(item.planId)}
                    //     class="mx-auto mt-4 text-primary text-Inter border-primary border-[1px] py-2 px-4 w-full font-semibold focus:outline-none hover:bg-priborder-primary rounded">
                    //     Buy
                    //   </button>
                    // )
                  }

                  {/* <p class="text-xs text-gray-500 mt-3 text-Inter">Literally you probably haven't heard of them jean shorts.</p> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.global.user,
  updateStripeData: state.stripe.updateStripeData,
  billingInfo: state.stripe.billingInfo,
  totalLeadlists: state.global.totalLeadlists,
  stripe: state.global.stripe,
});

const mapDispatchToProps = {
  handleSubscription,
  stripeCustomerPortal,
  updateStripe,
  getUserBillingInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Setting);
